.audio__play {
    width: 100%;
    min-width: 200px;
    max-width: 400px;
    height: 2rem;
    padding-bottom: 0.5rem;
    margin: auto;
  }
  audio::-webkit-media-controls-panel {
    background-color: var(--dominant-color);
  }