.field {
  display: grid;
  gap: 0.5rem;
  font-size: 1.1rem;
}
.field > span {
  font-weight: bold;
}
.text__field > input {
  background-color: #f1f1f1;
  border-radius: 0.3rem;
  padding: 0.3rem;
  outline: none;
  border: none;
}
.field__label > .required {
  color: red;
  font-weight: bold;
}
.telephone__field {
  display: grid;
  gap: 0.3rem;
}
.telephone__field > select {
  max-width: 150px;
}
