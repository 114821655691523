.message {
  padding: 0.3rem;
  display: flex;
  justify-content: flex-start;
  gap: 0.3rem;
  }
  .user{
    text-align: right;
    justify-content: flex-end;
    animation: flyFromInput .3s ease-in forwards;
  }

  @keyframes flyFromInput {
    0% {
      transform: translateY(150px) translateX(-150px);
    }
    100% {
      transform: translateY(0) translateX(0);
    }
  }
  .bot {
    animation: opaque 300ms ease-in forwards;
  }
  @keyframes opaque {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .message__wrapper {
    background-color: var(--primary-color);
    border: 1px solid var(--border-color);
    max-width: 90%;
    display: grid;
    color: var(--dominant-color);
    gap: 0.5rem;
    padding-inline: 0.3rem;
    word-wrap: break-word;
    text-align: justify;
    border-radius: 0.5rem;
  }
  .message__text > p{
    margin-block: 0.4rem;
  }
  .message__wrappperMe {
    text-align: justify;
    border: 1px solid var(--border-color);
    max-width: 90%;
    display: grid;
    word-wrap: break-word;
    gap: 0.1rem;
    padding-inline: 0.8rem;
    word-wrap: break-word;
    border-radius: 0.5rem;
    background-color: #f1f1f0;
  }
  .message__timestamp {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }
  .message__timestamp > small {
    color: gray;
  }
  .message__timestamp__user {
    align-items: flex-end;
  }

  .conversation_attributes {
    text-align: justify;
    border: 1px solid var(--border-color);
    display: grid;
    word-wrap: break-word;
    gap: 0.1rem;
    padding: 0.8rem;
    word-wrap: break-word;
    border-radius: 0.5rem;
    background-color: #f1f1f0;
  }

  .conversation-drawer{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    background-color: white ;
    overflow: hidden;
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }

  .slide-in {
    animation: slide-in 0.5s forwards;
    -webkit-animation: slide-in 0.5s forwards;
  }
  
  .slide-out {
    animation: slide-out 0.5s forwards;
    -webkit-animation: slide-out 0.5s forwards;
  }
  
  @keyframes slide-in {
    100% {
      transform: translateX(100%);
    }
  }
  
  @-webkit-keyframes slide-in {
    100% {
      -webkit-transform: translateX(100%);
    }
  }
  
  @keyframes slide-out {
    0% {
      transform: translateX(100%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(100%);
    }
  
    100% {
      -webkit-transform: translateX(0%);
    }
  }