.my-text-suggest {
  border: 2px solid var(--primary-color);
  padding: 0.3rem;
  border-radius: 0.3rem;
  color: var(--primary-color);
  margin: 0.3rem;
  text-wrap: nowrap;
  transition: all 0.3s ease-in;
  cursor: pointer;
  font-size: small;
}

.my-text-suggest:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.widget_powered__footer {
  text-align: center;
  background-color: var(--dominant-color);
  opacity: 0.8;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.widget__inputColor::-webkit-color-swatch {
  border-radius: 10px;
}
