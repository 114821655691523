:root {
  --primary-color: #3182ce;
  --dominant-color: white;
  --hover-icon-color: #eae7e7;
  --border-color: lightgrey;
  --box-shadow-color: #00000033;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: #3182ce;
  border-radius: 6px;
}
.chartjs-tooltip {
  position: absolute !important;
  pointer-events: none; /* Avoid interference with the chart */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3182ce;
}

.toast-success {
  color: rgb(255, 119, 0);
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.toast-error {
  color: red;
  color: white;
}
.Toastify__progress-bar {
  background: rgb(255, 119, 0); /* Change to your desired color for success */
}

.Toastify__progress-bar--error {
  background: red; /* Change to your desired color for error */
}

.assistantRow:hover {
  background-color: #f2f2f2; /* Set your desired grey color */
  cursor: pointer;
}

.rs-picker-toggle-value{
  color: blue;
}


.chakra-link :hover ,
.css-spn4bz:hover{
  color: white;
}
.react-drop{
  width: 100px;
}

.ql-toolbar.ql-snow {
  border-color: #ccc7;
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.ql-container.ql-snow {
  min-height: 150px;
  border-color: #ccc7;
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/* .css-spn4bz:hover {
  color: rgba(207, 17, 17, 0.259);
} */
