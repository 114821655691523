.date__field {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.8rem;
  font-size: 1.1rem;
}
.date__fieldInput > input {
  background-color: #f1f1f1;
  border-radius: 0.3rem;
  padding: 0.3rem;
  font-size: 1.1rem;
}
