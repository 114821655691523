.custom-date-range {
    max-width: 480px;
    /* Ensure the calendar fits within the popover */
}

.custom-date-range .rdrCalendarWrapper {
    max-width: 480px;
    /* Ensure the calendar fits within the popover */
}

.custom-date-range .rdrMonths {
    display: flex;
    flex-direction: row;
}

.custom-date-range .rdrMonth {
    flex: 1;
    min-width: 0;
}


.custom-date-range-picker {
    width: 300px !important;
    /* Adjust as needed */
}

.rdrCalendarWrapper.rdrDateRangeWrapper.custom-date-range.darkMode {
    background-color: #2d3748;
}
