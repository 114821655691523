.message__text {
    padding: 0.5rem;
}

.message__text p {
    text-wrap: break-word;
}

.message__text pre code {
    text-wrap : wrap;
}