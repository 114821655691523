.document__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    text-wrap: nowrap;
    font-size: x-small;
    background-color: white;
    border-radius: 1rem;
    gap: 0.2rem;
    padding: 0.4rem;
    cursor: pointer;
    transition: all ease-in 300ms;
}

.document__tile>a {
    text-decoration: none;
}

.document__tile:hover {
    opacity: 0.8;
}